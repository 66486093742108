import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Section from "../../components/containers/section"
import SectionContent from "../../components/containers/section-content"
import Copy from "../../components/content/copy"
import Heading from "../../components/content/heading"
import { Columns, Content, Rows } from "../../components/grid"
import Main from "../../components/layout/main"
import Card from "../../components/containers/card"
import CtaGroup from "../../components/containers/cta-group"
import Button from "../../components/interactive/button"
import BlogCategory from "../../components/accents/BlogCategory"
import HeadingAccent from "../../components/accents/heading-accent"
import SectionBackground from "../../components/containers/section-background"
import Listing from "../../components/blog/resource-listing"
import Pagination from "../../components/interactive/pagination"
import Seo from "../../components/seo/seo"
import Link from "@src/components/content/link"

function ResourcePage({ pageContext, data }) {
  const { resourcePage } = data.wpPage
  const featuredResource = pageContext.isSubPage
    ? pageContext.featuredPost
    : resourcePage.featuredResource[0]

  const featuredResourceImage = getImage(
    featuredResource.featuredImage.node.localFile,
  )

  const seoData = {
    title: pageContext.isSubPage
      ? pageContext.seo.title
      : data.wpPage.seo.title,
    description: pageContext.isSubPage
      ? pageContext.seo.metaDesc
      : data.wpPage.seo.metaDesc,
    url:
      pageContext.currentPage > 1
        ? `/${pageContext.rootslug}/${pageContext.currentPage}`
        : `/${pageContext.rootslug}/`,
  }

  return (
    <Main>
      <Seo customData={seoData} />
      <header className="mb-md">
        <Section className="mb-xxl tab-mb-none">
          <SectionBackground>
            <div className="hero-image--careers" />
          </SectionBackground>
          <SectionContent hero paddingBot="none">
            <Rows gap="none" className="mb-md">
              <Columns count="2,1">
                <Content paddingLeft="none">
                  <Heading
                    className="mt-xl lap-mr-xl"
                    alignment="left"
                    level={1}
                  >
                    {pageContext.isSubPage
                      ? pageContext.category
                      : resourcePage.pageTitle}
                  </Heading>
                  <Copy>
                    <p>
                      {pageContext.isSubPage
                        ? pageContext.description
                        : resourcePage.pageSubtext}
                    </p>
                  </Copy>
                </Content>
              </Columns>
            </Rows>
          </SectionContent>
        </Section>
      </header>

      <Section>
        <SectionContent paddingTop="none" paddingBot="sm">
          <Card
            styleType="solid"
            className="py-lg px-md lap-py-xl lap-px-xxl border--rounded width--full relative overflow--hidden"
            style={{ minHeight: 425 }}
          >
            <div className="absolute width--full height--full opc-10 flex flex--align-center">
              <GatsbyImage
                className="absolute width--full height--full"
                image={featuredResourceImage}
                alt={featuredResource.featuredImage.altText}
              />
            </div>
            <Rows className="z-10">
              <Columns count="1">
                <Content gap="md" paddingLeft="none" className="lap-mr-xxl">
                  <p>
                    <span className="h4 text-gradient text-gradient__nebula">
                      Featured Resource
                    </span>
                  </p>
                  <Link
                    className="h3"
                    to={featuredResource.resourceSchema.resourceLink}
                  >
                    {featuredResource.title}
                  </Link>
                  <p className="text--small mb-xxs">
                    {featuredResource.resourceSchema.resourceContent}
                  </p>
                  <CtaGroup>
                    <Button
                      color="secondary"
                      href={featuredResource.resourceSchema.resourceLink}
                    >
                      {featuredResource.resourceSchema.ctaText}
                    </Button>
                  </CtaGroup>
                </Content>
              </Columns>
            </Rows>
          </Card>
        </SectionContent>
      </Section>

      <Section>
        <SectionBackground>
          <HeadingAccent top={43} color="core" />
        </SectionBackground>
        <SectionContent paddingTop="none" paddingBot="xs">
          <CtaGroup>
            <Button
              href="/resources/"
              size="sm"
              color={pageContext.isSubPage ? "light" : "secondary"}
            >
              All
            </Button>
            {data.allWpResourceCategory.nodes.map(
              ({ name, slug, extraData }, index) => {
                const isCurrentCategory =
                  pageContext.isSubPage &&
                  pageContext.rootslug === `resources/${slug}`
                return (
                  <Button
                    key={index}
                    color={isCurrentCategory ? extraData.color : "light"}
                    href={`/resources/${slug}/`}
                    size="sm"
                  >
                    {name}
                  </Button>
                )
              },
            )}
          </CtaGroup>
        </SectionContent>
      </Section>

      <Section>
        <SectionContent paddingTop="none">
          <Rows gap="xl">
            <div className="blog__articles blog__articles--resources">
              {data.allWpResource.nodes.map((item, index) => (
                <Listing key={index} item={item} />
              ))}
            </div>
            <Pagination
              rootslug={pageContext.rootslug}
              numPages={pageContext.numPages}
              currentPage={pageContext.currentPage}
            />
          </Rows>
        </SectionContent>
      </Section>
    </Main>
  )
}

export const query = graphql`
  query ResourcesPageQuery($categoryId: [String]!, $skip: Int!, $limit: Int!) {
    allWpResourceCategory(filter: { name: { ne: "Uncategorized" } }) {
      nodes {
        name
        slug
        extraData {
          color
        }
      }
    }
    allWpResource(
      sort: { fields: date, order: DESC }
      filter: {
        resourceCategories: {
          nodes: { elemMatch: { id: { in: $categoryId } } }
        }
      }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        date(formatString: "MMMM Do, YYYY")
        slug
        resourceCategories {
          nodes {
            name
            slug
            extraData {
              color
              buttonCta
            }
          }
        }
        title
        resourceSchema {
          resourceContent
          resourceLink
          ctaText
        }
        featuredImage {
          node {
            altText
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData(width: 915, quality: 90, layout: CONSTRAINED)
              }
            }
          }
        }
      }
    }
    wpPage(slug: { eq: "resource-hub" }) {
      seo {
        title
        metaDesc
      }
      resourcePage {
        pageTitle
        pageSubtext
        featuredResource {
          ... on WpResource {
            title
            resourceSchema {
              resourceContent
              resourceLink
              ctaText
            }
            resourceCategories {
              nodes {
                name
                extraData {
                  buttonCta
                  color
                }
              }
            }
            featuredImage {
              node {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
                altText
              }
            }
          }
        }
      }
    }
  }
`

export default ResourcePage
